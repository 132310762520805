<template>
  <div v-html="formatedDescription" class="description">
    <router-link v-if="descriptionToLong" :to="to" class="text-primary">{{
      $t('item.readOn')
    }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      required: false,
      default: '',
      type: String
    },
    to: {
      required: true,
      default: function() {
        return {}
      },
      type: Object
    }
  },
  computed: {
    descriptionToLong: function() {
      if (this.description === null) return false
      if (this.description === '') return false
      if (this.description.length < 270) return false

      return true
    },
    formatedDescription: function() {
      let description = this.description
      if (description === null) return ''
      if (description === '') return ''

      description = description
        .replaceAll('<br>', '')
        .replaceAll('<br />\n', '')
        .replaceAll('<br />\n', '')
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')

      // To long
      if (this.descriptionToLong) return `${description.substring(0, 270)}...`

      return description
    }
  }
}
</script>

<style scoped lang="scss">
.description {
  font-size: 13px;
  color: #6c757d;
  max-height: 100px;
  display: -webkit-box;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
